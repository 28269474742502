/**
 * @file contains CheckoutForm component.
 */
import { Form } from "@ant-design/compatible";
import { getEnvVar } from "_common/utils/environmentVariables";
import { Button, Input, Modal } from "antd";
import { useCallback, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import defaultTheme from "themes/defaultTheme";
/**
 * Styled components styles.
 */
const StyledCheckoutFormContainer = styled.div`
  width: 100%;
  flex: 0 1 700px;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  padding: 24px 32px 42px;
`;
const StyledFormAddressWrapper = styled.div`
  ${({ langKey }) => {
    if (langKey === "jp") {
      // For YAMATO merchant
      return css`
        display: flex;
        flex-direction: column-reverse;
        & :nth-child(1) {
          order: -1;
        }
        & :nth-child(2) {
          order: -2;
        }
      `;
    }
    return css``;
  }}
`;
const StyledFormAddressWrapperLabel = styled.span`
  display: block;
  padding: 8px 0;
  font-size: 14px;
  font-weight: 500;
  color: #231f20;
`;
const StyledFormItemInput = styled(Form.Item)`
  margin-bottom: 16px;

  .ant-legacy-form-item-label {
    line-height: normal;
    padding-bottom: 6px;
    padding-top: 8px;
  }

  .ant-legacy-form-item-label label {
    font-size: 14px;
    font-weight: 500;
    color: #231f20;
    letter-spacing: -0.32px;
  }

  .ant-legacy-form-item-label label:before {
    display: none;
  }

  .ant-legacy-form-item-label label:after {
    display: none;
  }

  .ant-legacy-form-item-control input {
    height: 48px;
    border-radius: 3px;
    border: solid 1px #d8d8d8;
    background-color: #ffffff;
    color: #222222;
    font-size: 16px;
    font-family: ${defaultTheme.fonts.light};
  }

  .ant-legacy-form-item-control input::placeholder {
    color: #9b9b9b;
  }

  .ant-legacy-form-explain {
    font-family: ${defaultTheme.fonts.light};
    font-size: 14px;
    line-height: normal;
    letter-spacing: normal;
    color: rgb(215, 16, 27);
    text-align: right;
    margin-top: 6px;
  }
`;
const StyledFormItemButton = styled(Form.Item)`
  margin-bottom: 0;
  margin-top: 26px;

  .ant-legacy-form-item-children {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 1rem;
  }

  button {
    flex: 1;
    height: 50px;
    border-radius: 3px;
    border: solid 1px rgb(199, 199, 204);
    background-color: rgb(255, 255, 255);
    text-shadow: none;
    box-shadow: none;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0px;
    text-align: center;
    color: rgb(34, 34, 34);
  }

  button:hover {
    background-color: rgb(255, 255, 255);
    color: rgb(34, 34, 34);
    border: solid 1px rgb(199, 199, 204);
  }

  button[type="submit"] {
    background-color: rgba(215, 16, 27, 0.5);
    color: rgb(255, 255, 255);
    border: solid 1px rgba(215, 16, 27, 0.5);
  }

  button[type="submit"][disabled] {
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    color: rgba(0, 0, 0, 0.25);
  }
`;
const StyledLocationFinder = styled.div`
  margin: 26px 0 18px;
`;
const StyledTitle = styled.h1`
  font-size: 32px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.3px;
  color: rgb(35, 31, 32);
  margin: 0 0 30px;
`;
const langKey = getEnvVar("REACT_APP_LOCALIZATION") || "en";

/**
 * CheckoutForm component.
 */
function CheckoutForm({ form, translations }) {
  /**
   * useForm methods.
   */
  const { getFieldDecorator, validateFieldsAndScroll, getFieldsValue } = form;

  /**
   * Form submit button status.
   */
  const [submitButtonStatus, setSubmitButtonStatus] = useState(true);

  /**
   * On form change handler.
   */
  const onFormChange = useCallback(() => {
    const fieldsValue = getFieldsValue();
    let isAllReqFieldsFilled = false;
    Object.keys(fieldsValue).forEach((item) => {
      const itemValue = fieldsValue[item];
      if (itemValue === undefined || itemValue.length === 0)
        isAllReqFieldsFilled = true;
      setSubmitButtonStatus(isAllReqFieldsFilled);
    });
  }, [getFieldsValue, setSubmitButtonStatus]);

  /**
   * Location finder widget callback.
   * @param {object} storeAddress
   *  Address data.
   */
  const locationFinderCb = useCallback(
    (storeAddress) => {
      const { line1, line2, suburb, state, postcode } = storeAddress;
      form.setFieldsValue({
        street: line1,
        building: line2,
        address_4: suburb,
        address_5: state,
        address_6: postcode,
      });
      onFormChange();
      // eslint-disable-next-line
    },
    [form, onFormChange]
  );

  const configId = window.location.pathname.substring(1);
  let configUrl = "/2/" + configId;
  const carrierVar =
    getEnvVar("REACT_APP_LOCATION_FINDER_WIDGET_DOMAIN").includes(
      "/2/AU_POST"
    ) ||
    getEnvVar("REACT_APP_LOCATION_FINDER_WIDGET_DOMAIN").includes("/2/YAMATO");
  if (carrierVar) {
    configUrl = "";
  } else if (!configId && !carrierVar) {
    configUrl = "/2/dev";
  }

  /**
   * Init doodle location finder widget when library is loaded.
   * @param {object} DoddleLocationFinder
   *  Doddle Location Finder instance object.
   */
  const hasLoadedLocationFinder = useCallback(
    (LocationFinder) => {
      LocationFinder.init({
        root: "clickAndCollectWrapper",
        apiKey: getEnvVar("REACT_APP_DODDLE_API_KEY"),
        apiSecret: getEnvVar("REACT_APP_DODDLE_API_SECRET") || "",
        googleApiKey: getEnvVar("REACT_APP_GOOGLE_API_KEY") || "",
        merchantId: getEnvVar("REACT_APP_MERCHANT_ID") || "",
        emailInputId: "email",
        callback: locationFinderCb,
        // initialUserPostCode: '4000',
      });
    },
    [locationFinderCb]
  );

  /**
   * Use 'useEffect' from React hooks.
   */
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      `https://${getEnvVar(
        "REACT_APP_LOCATION_FINDER_WIDGET_DOMAIN"
      )}${configUrl}/location-finder.js` || "";
    script.addEventListener("load", () => {
      const ext = window["LocationFinder"];
      hasLoadedLocationFinder(ext);
    });
    document.body.appendChild(script);
    // eslint-disable-next-line
  }, []);

  /**
   * Modal window.
   */
  const modalWindow = (data) => {
    Modal.info({
      title: translations.checkoutForm.modal.title,
      content: (
        <div>
          <p>{translations.checkoutForm.modal.subtitle}:</p>
          {
            // eslint-disable-next-line
            Object.keys(data).map((key) => {
              if (data[key] !== undefined)
                return (
                  <p key={key}>
                    {key}: {data[key]}
                  </p>
                );
            })
          }
        </div>
      ),
      onOk() {},
    });
  };

  /**
   * Confirm order button handler.
   * @param {object} event
   *  Event data object.
   */
  const handleConfirmOrder = (event) => {
    event.preventDefault();
    // Validate form.
    validateFieldsAndScroll((err, values) => {
      if (!err) {
        modalWindow(values);
      }
    });
  };

  /**
   * Render component DOM.
   */
  return (
    <StyledCheckoutFormContainer>
      <StyledTitle>{translations.checkoutForm.title}</StyledTitle>
      <Form
        onSubmit={handleConfirmOrder}
        layout="vertical"
        onChange={onFormChange}
      >
        <StyledFormItemInput
          label={translations.checkoutForm.fields.name.label}
        >
          {getFieldDecorator("name")(
            <Input
              placeholder={translations.checkoutForm.fields.name.placeholder}
              size="large"
            />
          )}
        </StyledFormItemInput>
        <StyledFormItemInput
          label={translations.checkoutForm.fields.email.label}
        >
          {getFieldDecorator("email", {
            rules: [
              {
                type: "email",
                message: translations.checkoutForm.fields.email.message,
              },
              {
                required: true,
                message: translations.checkoutForm.fields.email.messageEmpty,
              },
            ],
            validateTrigger: "onBlur",
          })(
            <Input
              id="email"
              placeholder={translations.checkoutForm.fields.email.placeholder}
              size="large"
            />
          )}
        </StyledFormItemInput>
        <StyledLocationFinder id="clickAndCollectWrapper" />
        <StyledFormAddressWrapperLabel>
          {translations.checkoutForm.fields.address.label}
        </StyledFormAddressWrapperLabel>
        <StyledFormAddressWrapper langKey={langKey}>
          <StyledFormItemInput>
            {getFieldDecorator("street", {
              rules: [
                {
                  required: true,
                  message: translations.checkoutForm.fields.address.message,
                },
              ],
              validateTrigger: "onBlur",
            })(
              <Input
                placeholder={
                  translations.checkoutForm.fields.address.placeholders.street
                }
                size="large"
              />
            )}
          </StyledFormItemInput>
          <StyledFormItemInput>
            {getFieldDecorator("building", {
              rules: [
                {
                  required: true,
                  message: translations.checkoutForm.fields.address.message,
                },
              ],
              validateTrigger: "onBlur",
            })(
              <Input
                placeholder={
                  translations.checkoutForm.fields.address.placeholders.building
                }
                size="large"
              />
            )}
          </StyledFormItemInput>
          <StyledFormItemInput>
            {getFieldDecorator("address_4", {
              rules: [
                {
                  required: true,
                  message: translations.checkoutForm.fields.address.message,
                },
              ],
              validateTrigger: "onBlur",
            })(
              <Input
                placeholder={
                  translations.checkoutForm.fields.address.placeholders.suburb
                }
                size="large"
              />
            )}
          </StyledFormItemInput>
          <StyledFormItemInput>
            {getFieldDecorator("address_5", {
              rules: [
                {
                  required: true,
                  message: translations.checkoutForm.fields.address.message,
                },
              ],
              validateTrigger: "onBlur",
            })(
              <Input
                placeholder={
                  translations.checkoutForm.fields.address.placeholders.state
                }
                size="large"
              />
            )}
          </StyledFormItemInput>
          <StyledFormItemInput>
            {getFieldDecorator("address_6", {
              rules: [
                {
                  required: true,
                  message: translations.checkoutForm.fields.address.message,
                },
              ],
              validateTrigger: "onBlur",
            })(
              <Input
                placeholder={
                  translations.checkoutForm.fields.address.placeholders.postcode
                }
                size="large"
              />
            )}
          </StyledFormItemInput>
        </StyledFormAddressWrapper>
        <StyledFormItemButton>
          <Button type="primary">
            {translations.checkoutForm.buttons.continueShopping}
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            disabled={submitButtonStatus}
          >
            {translations.checkoutForm.buttons.confirmOrder}
          </Button>
        </StyledFormItemButton>
      </Form>
    </StyledCheckoutFormContainer>
  );
}

export default Form.create()(CheckoutForm);
