import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import CheckoutForm from './components/CheckoutForm';
import CheckoutCart from './components/CheckoutCart';
import Footer from './components/Footer';
import Header from './components/Header';
import translationsObj from './_common/translations';
import { getEnvVar } from './_common/utils/environmentVariables';

const StyledMain = styled.main`
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 4.75rem;
`;
const langKey = getEnvVar('REACT_APP_LOCALIZATION') || 'en';

function App() {
    const translations = useMemo(() => translationsObj[langKey], []);
    const logoPrefix = useMemo(() => (langKey === 'jp' ? 'yamato' : 'ap'), []);

    useEffect(() => {
        window.document.documentElement.lang =
            langKey === 'jp' ? 'ja' : langKey;
    }, []);

    return (
        <div className="App">
            <Header translations={translations} />
            <StyledMain className="container">
                <CheckoutForm translations={translations} />
                <CheckoutCart translations={translations} />
            </StyledMain>
            <Footer translations={translations} logoPrefix={logoPrefix} />
        </div>
    );
}

export default App;
