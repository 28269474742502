/**
 * @file contains Footer component.
 */
import React from 'react';
import styled from 'styled-components';
import defaultTheme from '../../themes/defaultTheme';

/**
 * Styled components styles.
 */
const StyledFooterContainer = styled.footer`
    background-color: rgb(34, 34, 34);
    padding-top: 1rem;
    padding-bottom: 1rem;
`;
const StyledFooterContainerInnerWrapper = styled.div`
    gap: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: left;

    @media screen and (min-width: 768px) {
        justify-content: space-between;
        flex-direction: row;
    }
`;
const StyledFooterPrivacy = styled.div`
    font-family: ${defaultTheme.fonts.light};
    font-size: 0.75rem;
    text-align: center;
    color: rgb(255, 255, 255);

    @media screen and (min-width: 768px) {
        text-align: right;
    }
`;

/**
 * Footer component.
 */
function Footer({ translations, logoPrefix }) {
    /**
     * Render component DOM.
     */
    return (
        <StyledFooterContainer>
            <StyledFooterContainerInnerWrapper className="container">
                <img
                    alt=""
                    width={34}
                    height={34}
                    src={`/images/footer-logo-${logoPrefix}.png`}
                />
                <StyledFooterPrivacy>
                    {translations.footer.message}
                </StyledFooterPrivacy>
            </StyledFooterContainerInnerWrapper>
        </StyledFooterContainer>
    );
}

export default Footer;
