import "antd/dist/reset.css";
import ReactDOM from "react-dom";
import { ThemeProvider, createGlobalStyle } from "styled-components";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import themes from "./themes";
import "./themes/globals.css";

const GlobalStyles = createGlobalStyle`
  :root {
    --container-max-width: 1028px;
    --container-gutter: 1rem;
  }
`;

ReactDOM.render(
  <ThemeProvider theme={themes.default}>
    <GlobalStyles />
    <App />
  </ThemeProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
