/**
 * @file contains CheckoutCart component.
 */
import React, { useState } from 'react';
import styled from 'styled-components';
import { Button } from 'antd';
import defaultTheme from '../../themes/defaultTheme';

/**
 * Styled components styles.
 */
const StyledCheckoutCartContainer = styled.aside`
    width: 100%;
    flex: 0 1 300px;
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.2);
    background-color: rgb(255, 255, 255);
    padding: 0 22px 30px;
`;
const StyledCartProduct = styled.div`
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding: 20px 10px;
    clear: both;
`;
const StyledCartProductImg = styled.img`
    width: 72px;
    height: 96px;
    float: left;
    margin-right: 20px;
`;
const StyledCartStoreName = styled.div`
    font-size: 16px;
    font-weight: 500;
    line-height: 1.88;
    color: rgb(0, 21, 38);
`;
const StyledCartProductMain = styled.div`
    font-family: ${defaultTheme.fonts.light};
    font-size: 14px;
    font-weight: 300;
    line-height: 2.29;
    color: rgb(106, 107, 110);
`;
const StyledCartProductType = styled(StyledCartProductMain)`
    line-height: 1.2;
    padding-top: 4px;
`;
const StyledCartProductBottomContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 14px;
`;
const StyledCartSubtotal = styled.div`
    font-family: ${defaultTheme.fonts.light};
    font-size: 14px;
    font-weight: 300;
    line-height: 2.14;
    color: rgb(0, 21, 38);
    display: flex;
    justify-content: space-between;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding: 10px 10px 12px;
`;
const StyledCartTotal = styled(StyledCartSubtotal)`
    font-family: ${defaultTheme.fonts.basic};
    font-size: 18px;
    font-weight: 500;
    line-height: 1.78;
    color: rgb(0, 0, 0);
    padding: 16px 10px 0;
`;
const StyledCartHeadContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 22px 10px;
`;
const StyledCartEditButton = styled(Button)`
    border-radius: 3px;
    border: solid 1px rgb(199, 199, 204);
    background-color: rgb(255, 255, 255);
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0px;
    text-align: center;
    color: rgb(34, 34, 34);
    min-width: 60px;
`;
const StyledCartCounter = styled.div`
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.32px;
    color: rgb(35, 31, 32);
`;

/**
 * CheckoutCart component.
 */
function CheckoutCart({ translations }) {
    /**
     * Sample products data.
     */
    const [productsData] = useState([
        {
            id: 1,
            image: '/images/product_1.png',
            storeName: translations.checkoutCart.product.storeName,
            type: translations.checkoutCart.product.types.carvela,
            size: 8,
            price: 50.0,
        },
        {
            id: 2,
            image: '/images/product_2.png',
            storeName: translations.checkoutCart.product.storeName,
            type: translations.checkoutCart.product.types.jacket,
            size: 8,
            price: 100.0,
        },
    ]);

    /**
     * Edit cart button handler.
     */
    const editCartHandler = () => {
        console.log('Edit cart handler');
    };

    /**
     * Render component DOM.
     */
    return (
        <StyledCheckoutCartContainer>
            <StyledCartHeadContainer>
                <StyledCartCounter>
                    {productsData.length} {translations.checkoutCart.title}
                </StyledCartCounter>
                <StyledCartEditButton onClick={editCartHandler}>
                    {translations.checkoutCart.buttonEdit}
                </StyledCartEditButton>
            </StyledCartHeadContainer>
            {productsData.map(({ id, storeName, type, size, price, image }) => (
                <StyledCartProduct key={id}>
                    <StyledCartProductImg src={image} alt={type} />
                    <StyledCartStoreName>{storeName}</StyledCartStoreName>
                    <StyledCartProductType>{type}</StyledCartProductType>
                    <StyledCartProductBottomContainer>
                        <StyledCartProductMain>
                            {translations.checkoutCart.product.size} {size}
                        </StyledCartProductMain>
                        <StyledCartProductMain>
                            {translations.checkoutCart.product.currency}
                            {price}
                        </StyledCartProductMain>
                    </StyledCartProductBottomContainer>
                </StyledCartProduct>
            ))}
            <StyledCartSubtotal>
                <span>{translations.checkoutCart.table.subtotal}</span>
                <span>{translations.checkoutCart.table.currency}150.00</span>
            </StyledCartSubtotal>
            <StyledCartTotal>
                <span>{translations.checkoutCart.table.total}</span>
                <span>{translations.checkoutCart.table.currency}150.00</span>
            </StyledCartTotal>
        </StyledCheckoutCartContainer>
    );
}

export default CheckoutCart;
