/**
 * @file contains Header component.
 */
import React, { useState } from 'react';
import styled from 'styled-components';
import { Input } from 'antd';
import defaultTheme from '../../themes/defaultTheme';
import { Button } from 'antd';

/**
 * Styled components styles.
 */
const StyledHeader = styled.header`
    margin-bottom: 1rem;
    background-color: rgb(255, 255, 255);

    @media screen and (min-width: 768px) {
        margin-bottom: 2rem;
    }
`;

const StyledHeaderMessage = styled.div`
    font-family: ${defaultTheme.fonts.light};
    background-color: rgb(35, 31, 32);
    text-align: center;
    color: rgb(255, 255, 255);
    padding: 7px 1.5rem;
`;
const StyledHeaderMainMenu = styled.ul`
    list-style: none;
    border-top: 1px solid rgb(230, 231, 232);
    margin-bottom: 0;
    padding: 18px 0;
    display: flex;
    flex-wrap: wrap;
    row-gap: 1rem;
    width: 100%;
    justify-content: center;

    @media screen and (min-width: 768px) {
        column-gap: 2rem;
    }

    @media screen and (min-width: 992px) {
        column-gap: 3.125rem;
    }

    a {
        color: rgb(62, 63, 66);
    }
`;
const StyledHeaderRow = styled.div`
    height: 5rem;
    gap: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (min-width: 768px) {
        height: 8rem;
        align-items: flex-end;
        padding-bottom: 1rem;
    }
`;

const StyledHeaderTopLogo = styled.span`
    font-family: ${defaultTheme.fonts.ultra};
    font-size: 1.6rem;
    line-height: 1;
    letter-spacing: -0.73px;
    color: rgb(35, 31, 32);
    position: relative;
    top: 0.25rem;

    @media screen and (min-width: 768px) {
        font-size: 2rem;
        position: static;
    }
`;
const StyledHeaderSearch = styled(Input.Search)`
    display: none;

    @media screen and (min-width: 768px) {
        display: block;
        margin-bottom: 0.75rem;
    }
`;

const StyledMobileLinks = styled.div`
    display: flex;
    gap: 0.5rem;

    ${Button} {
        @media screen and (min-width: 768px) {
            display: none;
        }
    }
`;

const StyledHeaderNav = styled.nav`
    display: none;
    width: 100%;
    max-width: var(--container-max-width);
    margin: 0 auto;

    @media screen and (min-width: 768px) {
        display: block;
    }
`;

/**
 * Header component.
 */
function Header({ translations }) {
    /**
     * Sample menu data.
     */
    const [menuData] = useState([
        {
            id: 1,
            title: translations.header.navMenu.newIn,
            url: '/some/item/1',
        },
        {
            id: 2,
            title: translations.header.navMenu.clothing,
            url: '/some/item/2',
        },
        {
            id: 3,
            title: translations.header.navMenu.dresses,
            url: '/some/item/3',
        },
        {
            id: 4,
            title: translations.header.navMenu.shoes,
            url: '/some/item/4',
        },
        {
            id: 5,
            title: translations.header.navMenu.accessories,
            url: '/some/item/5',
        },
        {
            id: 6,
            title: translations.header.navMenu.kids,
            url: '/some/item/6',
        },
        {
            id: 7,
            title: translations.header.navMenu.inspireMe,
            url: '/some/item/7',
        },
    ]);

    /**
     * Render component DOM.
     */
    return (
        <StyledHeader>
            <div className="container">
                <StyledHeaderRow>
                    <StyledHeaderTopLogo>
                        {translations.header.logo}
                    </StyledHeaderTopLogo>

                    <div>
                        <StyledMobileLinks>
                            <Button icon="menu" aria-label="Menu" />
                            <Button icon="search" aria-label="Search" />
                        </StyledMobileLinks>
                        <StyledHeaderSearch
                            placeholder={translations.header.search.placeholder}
                        />
                    </div>
                </StyledHeaderRow>

                <StyledHeaderNav>
                    <StyledHeaderMainMenu>
                        {menuData.map(({ id, title, url }) => (
                            <li key={id}>
                                <a href={url}>{title}</a>
                            </li>
                        ))}
                    </StyledHeaderMainMenu>
                </StyledHeaderNav>
            </div>
            <StyledHeaderMessage>
                {translations.header.promotionMessage}
            </StyledHeaderMessage>
        </StyledHeader>
    );
}

export default Header;
