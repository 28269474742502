import { get } from "lodash";

const environmentLoadExternal = "externalProcessEnv";

/**
 * Helper method to grab the requested environment variable from the window object
 * where they will now live when using "react-env" using lodash,
 * so we don't get any nasty "object not found" errors. Allows
 * passing in a default value.
 *
 * @param environmentVariablePath
 * @param defaultValue
 */
export const getEnvVar = (environmentVariablePath, defaultValue) => {
  /**
   * Check that we have env.js enabled for usage in the index.html
   * This environment variable is adjusted via the Makefile in the root, only run on kube
   */
  if (get(window, "ENVIRONMENT_LOAD") === environmentLoadExternal) {
    return get(window, `_env.${environmentVariablePath}`, defaultValue);
  }
  return get(process.env, `${environmentVariablePath}`, defaultValue);
};
